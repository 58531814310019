<template>
  <div id="info-progress">
		<div id="info-progress-content" v-if="open">
			<b-pagination
				:current.sync="currentPageSidebar"
				:total="section.counter"
				:per-page="section.questionToDisplay"
				class="is-small"
				simple
			></b-pagination>
			<hr>
			<div class="columns">
				<div class="column is-12">
					<div class="buttons">
						<b-button v-for="(question, i) in section.questionList" :key="i"
							size="is-small" rounded
							:type="buttonType(question)"
							@click="scrollToQuestion(question)"
							v-show="
								section.questionToDisplay * (currentPageSidebar - 1) < question.index &&
								section.questionToDisplay * currentPageSidebar >= question.index
							">
							{{question.index}}
						</b-button>
					</div>
				</div>
			</div>
			<hr>
			<div class="columns">
				<div class="column is-12 buttons is-size-7">
					<p><b-button type="is-light" size="is-small" rounded>{{totalUnanswered}}</b-button> Kosong (Unanswered)</p>
					<p><b-button type="is-hcc" size="is-small" rounded>{{totalAnswered}}</b-button> Sudah terjawab (Answered)</p>
					<p><b-button type="is-warning" size="is-small" rounded>{{totalMarkedQuestion}}</b-button> Periksa kembali (Check Again)</p>
				</div>
				<div class="column-12 is-hidden-desktop has-text-right">
					<b-button @click="open = false" 
						type="is-success" 
						size="is-small" 
            class="is-responsive"
            icon-right="times"
						outlined
					>Close</b-button>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  props: {
		section: {
      default: () => {},
    },
		currentPage: {
			default: () => 1
		}
	},
  data() {
    return {
			isMobile: window.innerWidth < 769,
			open: !this.isMobile,
			currentPageSidebar: 1,
    }
  },
	watch:{
		currentPage(val){
			this.currentPageSidebar = val
		},
		currentPageSidebar(val){
			this.$emit('change-page', val)
		}
	},
  computed:{
		questionProgress(){
			let total = this.section.counter
			let totalAnswered = this.section.questionList.filter(question => question.hasAnswer == true).length
			return totalAnswered +' / ' + total
		},
		totalUnanswered(){
			return this.section.questionList.filter(question => question.hasAnswer == false).length
		},
		totalAnswered(){
			return this.section.questionList.filter(question => question.hasAnswer == true).length
		},
		totalMarkedQuestion(){
			return this.section.questionList.filter(question => question.checkLater == true).length
		},
	},
	methods:{
		toggleSidebar(){
			this.open = !this.open
		},
		openSidebar(){
			this.open = true
		},
		closeSidebar(){
			this.open = false
		},
		scrollToQuestion(question){
			if(this.isMobile) this.open = false
			setTimeout(()=>{
				this.$emit('uncheck-later', question)
			}, 1500)

			document.getElementById('question-'+question.id)
				.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
		},
		buttonType(question){
			if(question.checkLater) return 'is-warning'
			else if(question.hasAnswer) return 'is-hcc'
			else return 'is-light'
		}
	},
	mounted(){
		window.addEventListener('resize', () => {
			this.open = window.innerWidth > 768
		})
	}
}
</script>

<style lang="scss">
#info-progress {
	max-height: 90vh;
	overflow: auto;
	position: fixed;
	top: 35px;
	z-index: 1;
	width: 100%;

	#info-progress-content{
		margin-bottom: 10px;
		background-color: hsl(0, 0%, 100%);
		-webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10.2, 10.2, 10.2, 0.1), 0 0px 0 1px rgba(10.2, 10.2, 10.2, 0.02);
		box-shadow: 0 0.5em 1em -0.125em rgba(10.2, 10.2, 10.2, 0.1), 0 0px 0 1px rgba(10.2, 10.2, 10.2, 0.02);
		padding: 1.25rem;

		.pagination.is-simple{
			justify-content: end;

			.info {
				text-align: left;
				flex-grow: 1;
			}
			.pagination-list{
				order: 1;
			}
			.pagination-previous {
				order: 2;
			}
			.pagination-next {
				order: 3;
			}
		}
		.buttons button {
			flex-basis: calc(100% / 6);
		}
	}	
}
@media screen and (min-width: 769px){
	#info-progress{
		position: fixed;
		width: inherit;
		padding-right: initial;
		padding-bottom: 25px;
		top: auto;
		z-index: auto;

		#info-progress-content{
			box-shadow:none;
		}
  }
}
</style>